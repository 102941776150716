/////////////////////////////////////////
// COmponents
/////////////////////////////////////////
const svg4everybody = require('svg4everybody');
import OverlayScrollbars from 'overlayscrollbars'; // or "import SimpleBar from 'simplebar';" if you want to use it manually.
import 'overlayscrollbars/css/OverlayScrollbars.css';
import Swiper from 'swiper';
const lockAudio = require('../assets/lock.mp3');
/////////////////////////////////////////
// APP
/////////////////////////////////////////
window.app = {

	data: {
		pack1: {
			title: 'The Boardroom',
			subtitle: 'Dark woods, glass & fanned Digestives',
			packDescription: "You're not allowed in there because the furniture costs more than your house, but when the CEO's away, the norms will play. This image pack gives half your team a million dollar view of the city and half of them an eyeful of £15 stick-on frosted glass. Other backgrounds in this pack include: ",
			packDescriptionList: [

				"Making a presentation",
				"Sat way down the end",
				"We found the HDMI!",
				"Waiting for this meeting to end outside"

			],

			gallery: [

				'./gals/pack1/gal/gal_Pack1_001.jpg',
				'./gals/pack1/gal/gal_Pack1_002.jpg',
				'./gals/pack1/gal/gal_Pack1_003.jpg',
				'./gals/pack1/gal/gal_Pack1_004.jpg',
				'./gals/pack1/gal/gal_Pack1_005.jpg'

			],
			thumbs: [

				'./gals/pack1/thumb/thmb_Pack1_001.jpg',
				'./gals/pack1/thumb/thmb_Pack1_002.jpg',
				'./gals/pack1/thumb/thmb_Pack1_003.jpg',
				'./gals/pack1/thumb/thmb_Pack1_004.jpg',
				'./gals/pack1/thumb/thmb_Pack1_005.jpg'

			]
		},
		pack2: {
			title: 'Quirky Brainstorm Room',
			subtitle: 'All those ideas. All that Ikea.',
			packDescription: "There'll be exposed brick, a quote from Steve Jobs on the wall and a pair of Converse nailed to a door. With this pack you can get your team back into their creative groove, in that 'cool' room you instantly regretted decorating the way you did. Other backgrounds found in this pack include:",
			packDescriptionList: [

				"Using the flipchart",
				"Loads of post-it notes everywhere",
				"Sat next to the guy who wears a beanie indoors"

			],

			gallery: [

				'./gals/pack2/gal/Pack2_001.jpg',
				'./gals/pack2/gal/Pack2_002.jpg',
				'./gals/pack2/gal/Pack2_003.jpg',
				'./gals/pack2/gal/Pack2_004.jpg',
				'./gals/pack2/gal/Pack2_005.jpg'

			],
			thumbs: [

				'./gals/pack2/thumb/Pack2_001.jpg',
				'./gals/pack2/thumb/Pack2_002.jpg',
				'./gals/pack2/thumb/Pack2_003.jpg',
				'./gals/pack2/thumb/Pack2_004.jpg',
				'./gals/pack2/thumb/Pack2_005.jpg'

			]
		},
		pack3: {
			title: 'Pulling a Late One',
			subtitle: "Proof you're indispensable!",
			packDescription: "If you long for that feeling of missing all the important moments in your child's life because you're being worked too hard, then the PALO pack is for you. This simple, effective backgrounds pack lets you work in an office environment when the lights have been shut off. Pack includes:",
			packDescriptionList: [

				"Open plan",
				"The boss's desk",
				"Pitch black"

			],

			gallery: [

				'./gals/pack3/gal/Pack3_001.jpg',
				'./gals/pack3/gal/Pack3_002.jpg',
				'./gals/pack3/gal/Pack3_003.jpg',
				'./gals/pack3/gal/Pack3_004.jpg',
				'./gals/pack3/gal/Pack3_005.jpg',

			],
			thumbs: [

				'./gals/pack3/thumb/Pack3_001.jpg',
				'./gals/pack3/thumb/Pack3_002.jpg',
				'./gals/pack3/thumb/Pack3_003.jpg',
				'./gals/pack3/thumb/Pack3_004.jpg',
				'./gals/pack3/thumb/Pack3_005.jpg',

			]
		},
		pack4: {
			title: 'The Work Kitchen',
			subtitle: 'Insert something funny about coffee HERE',
			packDescription: "If you're still wondering what happened to Chris in IT on Friday night, download our kitchen background pack for a good old fashioned gossip. The first background pack developed specifically for Monday mornings, it will also include:",
			packDescriptionList: [

				"Cupboard full of horrible mugs",
				"IKEA IKEA IKEA",
				"Help yourself to all the Dorset Cereals",
				"You're the subject of the gossip"

			],

			gallery: [

				'./gals/pack4/gal/Pack4_001.jpg',
				'./gals/pack4/gal/Pack4_002.jpg',
				'./gals/pack4/gal/Pack4_003.jpg',
				'./gals/pack4/gal/Pack4_004.jpg',
				'./gals/pack4/gal/Pack4_005.jpg'

			],
			thumbs: [

				'./gals/pack4/thumb/Pack4_001.jpg',
				'./gals/pack4/thumb/Pack4_002.jpg',
				'./gals/pack4/thumb/Pack4_003.jpg',
				'./gals/pack4/thumb/Pack4_004.jpg',
				'./gals/pack4/thumb/Pack4_005.jpg'

			]
		},
		pack5: {
			title: 'Lunchtime Drinks',
			subtitle: 'Coz the gym is for losers!',
			packDescription: "You need it by Friday, right? Whether cheers-ing a job well done, or secretly blaming your boss for messing it all up. This pack gives you all the excuses to stay for just one more. Alongside some classic pub interiors, you’ll also receive: ",
			packDescriptionList: [

				"The group that always congregates around the fruity",
				"Smoking garden",
				"Pub toilet",
				"We're gonna try and find a seat"

			],

			gallery: [

				'./gals/pack5/gal/Pack5_001.jpg',
				'./gals/pack5/gal/Pack5_002.jpg',
				'./gals/pack5/gal/Pack5_003.jpg',
				'./gals/pack5/gal/Pack5_004.jpg',
				'./gals/pack5/gal/Pack5_005.jpg'

			],
			thumbs: [

				'./gals/pack5/thumb/Pack5_001.jpg',
				'./gals/pack5/thumb/Pack5_002.jpg',
				'./gals/pack5/thumb/Pack5_003.jpg',
				'./gals/pack5/thumb/Pack5_004.jpg',
				'./gals/pack5/thumb/Pack5_005.jpg'

			]
		},
		pack6: {
			title: 'The Work Social',
			subtitle: 'Team bonding is important',
			packDescription: "Once a month, you roll your eyes at your company's attempt at organised fun and once a month you get absolutely steaming whilst divulging your darkest personal secrets. It must be the work social. In this pack you'll receive backgrounds for Go-Karting & Urban Axe-Throwing. You'll also get:",
			packDescriptionList: [

				"A crap cocktail bar after",
				"Petrol station for some cans",
				"Chicken place",
				"Nightbus"

			],

			gallery: [

				'./gals/pack6/gal/Pack6_001.jpg',
				'./gals/pack6/gal/Pack6_002.jpg',
				'./gals/pack6/gal/Pack6_003.jpg',
				'./gals/pack6/gal/Pack6_004.jpg',
				'./gals/pack6/gal/Pack6_005.jpg'

			],
			thumbs: [

				'./gals/pack6/thumb/Pack6_001.jpg',
				'./gals/pack6/thumb/Pack6_002.jpg',
				'./gals/pack6/thumb/Pack6_003.jpg',
				'./gals/pack6/thumb/Pack6_004.jpg',
				'./gals/pack6/thumb/Pack6_005.jpg'

			]
		},
		pack7: {
			title: "Client Lunch (They're Paying)",
			subtitle: "The menu doesn't even have prices",
			packDescription: "So what if it's not the 90's anymore! This background pack will bring you right back to the good times; silver service, types of cutlery you've never seen before, lemons wrapped in muslin; the works. The pack will also include:",
			packDescriptionList: [

				"Something-berry reduction",
				"These toilets are nicer than my flat",
				"Night cap at hotel bar"

			],

			gallery: [

				'./gals/pack7/gal/Pack7_001.jpg',
				'./gals/pack7/gal/Pack7_002.jpg',
				'./gals/pack7/gal/Pack7_003.jpg',
				'./gals/pack7/gal/Pack7_004.jpg',
				'./gals/pack7/gal/Pack7_005.jpg'

			],
			thumbs: [

				'./gals/pack7/thumb/Pack7_001.jpg',
				'./gals/pack7/thumb/Pack7_002.jpg',
				'./gals/pack7/thumb/Pack7_003.jpg',
				'./gals/pack7/thumb/Pack7_004.jpg',
				'./gals/pack7/thumb/Pack7_005.jpg'

			]
		},
		pack8: {
			title: "Client Lunch (You're Paying)",
			subtitle: 'You can spend £35 per head',
			packDescription: "It's not the 90's anymore! Instead of supermodels at the table next to you, there are kids drawing on the tablecloth. This pack will also include:",
			packDescriptionList: [

				"There's a salad bar?",
				"Waiting at the bus-stop"

			],

			gallery: [

				'./gals/pack8/gal/Pack8_001.jpg',
				'./gals/pack8/gal/Pack8_002.jpg',
				'./gals/pack8/gal/Pack8_003.jpg'

			],
			thumbs: [

				'./gals/pack8/thumb/Pack8_001.jpg',
				'./gals/pack8/thumb/Pack8_002.jpg',
				'./gals/pack8/thumb/Pack8_003.jpg'

			]
		}


	},

	queryParam: 'jgDonationId', // set which query param to look for
	donate: false,

	// Store the gallery sliders
	previews: {
		gals: [],
		thumbs: [],
	},
	scrollBars: null, // global ostorage for custom scrollbars

	/////////////////////////////////////////
	// START UP PLAY
	/////////////////////////////////////////
	init: () => {
		//console.log('HELLO APP.JS');

		// inline SVGS
		svg4everybody({
			polyfill: true // polyfill <use> elements for External Content
		});

		// Stop here if donation page
		if (app.page == 'page-donate') return;

		// Do only on MAIN page

		app.donationCheckQuery();

		// Setup all the Slider
		//let $previews = document.querySelectorAll('.packPreview');
		//previews.forEach(($this, i) => {
		//	app.slidersSetupAll($this, i);
		//});




		// TODO: SETUP ALL THE MODALS?
		//	app.initSl()


		// Listen for DOM events
		app.bindEvents();
		app.onWindowResize();

	},

	/////////////////////////////////////////
	// DOM EVENTS
	/////////////////////////////////////////
	bindEvents: () => {

		app.setClassClick('js-openMain', () => {
			app.contentSwitch('main');
		});

		app.setClassClick('js-openDonate', () => {
			app.contentSwitch('donate');
		});

		app.setClassClick("js-previewOpen", app.showPackModal);
		app.setClassClick("js-closeModal", app.hidePackModal);

		app.setClassClick("js-folder", app.onFolderClick);

		app.setClassClick("js-homebtn", () => {


			if (app.scrollBars) {
				app.scrollBars.scroll(0)
			} else {
				window.scrollTo(0, 0)
			}

		});

	},

	/////////////////////////////////////////
	// Window Events
	/////////////////////////////////////////
	onWindowResize: () => {
		let winW = window.innerWidth;
		let winH = window.innerHeight;

		if (winW >= 992) {

			if (!app.scrollBars) {

				app.activateScrollBars();
			}
		} else {
			if (app.scrollBars) {
				app.scrollBars.destroy();
				app.scrollBars = null;
			}
		}
	},
	/////////////////////////////////////////
	// onSoundClick
	/////////////////////////////////////////
	onFolderClick: () => {
		var sound = new Audio(lockAudio);

		sound.play();

	},

	/////////////////////////////////////////
	// UTILITY CLASSES
	/////////////////////////////////////////

	setClassClick: (className, func) => {

		var elements = document.getElementsByClassName(className);
		for (var i = 0; i < elements.length; i++) {
			elements[i].addEventListener('click', func);
		}

	},



	/////////////////////////////////////////
	// PACK OVERLAYS
	/////////////////////////////////////////
	hidePackModal: () => {

		const $body = document.getElementsByTagName("body")[0];
		$body.classList.remove('preview');

		document.getElementById('gallery-wrapper').innerHTML = '';
		document.getElementById('thumb-wrapper').innerHTML = '';
		let siteCont = document.querySelectorAll('.site__content');

		for (var i = 0; i < siteCont.length; i++) {
			siteCont[i].classList.remove('hidden')
		}


		let site = document.getElementsByClassName('site__scroll')[0];
		site.scrollTop = app.currentScroll;

		document.getElementById('js-overlay').classList.add('hidden')

	},
	showPackModal: (e) => {

		const $body = document.getElementsByTagName("body")[0];
		$body.classList.add('preview');

		let siteCont = document.querySelectorAll('.site__content');
		for (var i = 0; i < siteCont.length; i++) {
			siteCont[i].classList.add('hidden')
		}

		document.getElementById('gallery').classList.add('fade-in');

		var packName = e.target.getAttribute('data-id');

		//
		//var fruitCount = plant.getAttribute('data-fruit');

		let site = document.getElementsByClassName('site__scroll')[0];
		//site.classList.add('blockOverflow');
		app.currentScroll = site.scrollTop;
		site.scrollTop = 0;
		document.getElementById('js-overlay').classList.remove('hidden')
		app.initSlider(packName);

		// marquee
		let $marq = document.getElementById('js-overlayMarq');
		document.getElementById('js-overlay').removeChild($marq);;
		document.getElementById('js-overlay').insertBefore($marq, document.getElementById('js-overlay').firstChild);


		$marq.stop();
		$marq.start();
	},

	/////////////////////////////////////////
	// Custoim Scroll bars
	/////////////////////////////////////////
	activateScrollBars: (on) => {

		let winW = window.innerWidth;

		app.scrollBars = OverlayScrollbars(document.getElementById('js-scroll'), {
			overflowBehavior: {
				x: 'hidden'
			},
			scrollbars: {
				autoHide: false
			}
		});

	},

	/////////////////////////////////////////
	// Donation Checking Functions
	/////////////////////////////////////////

	//:::::::::::::::::::::::::::::::::::::::
	// Check for url query
	//:::::::::::::::::::::::::::::::::::::::
	donationCheckQuery: () => {

		// get the window url and split of the ?query
		const query = window.location.href.slice(window.location.href.indexOf('?') + 1);

		// TEST QUERY
		if (query.indexOf("jgDonationId") == -1) {

		} else {

			// Query Found...
			// get the donation ID form the query string
			let donationId = query.split('=');
			donationId = donationId[1];
			app.donationCheckApi(donationId); // check donation against api

		}

	},

	//:::::::::::::::::::::::::::::::::::::::
	// Check for donation
	//:::::::::::::::::::::::::::::::::::::::
	donationCheckApi: (donationId) => {

		const checking = setInterval(() => {}, 1000)

		// request to api
		const request = new XMLHttpRequest();
		request.open("GET", "https://api.justgiving.com/bbd577e0/v1/donation/" + donationId + "/status", false);
		request.send();

		// console.log('CHECKING API');

		if (request.status != 200) {
			app.switchCheckingMode();

			let checkCount = 0;
			let recheck = setInterval(() => {
				// console.log('CHECKING API AGAIN!');
				request.open("GET", "https://api.justgiving.com/bbd577e0/v1/donation/" + donationId + "/status", false);
				request.send();
				checkCount++;

				if (request.status === 200) {

					app.switchDownloadMode();
					clearInterval(recheck);
				} else if (checkCount >= 5) {

					//app.switchErrorMode();
					app.switchDownloadMode();
					clearInterval(recheck);

				}
			}, 1000);

		} else {
			app.switchDownloadMode();
		}

		// Check req status


	},

	/////////////////////////////////////////
	// Switching between Donate/Download modes
	/////////////////////////////////////////
	contentSwitch: (contName) => {
		let site = document.getElementsByClassName('site__scroll')[0];
		site.scrollTop = 0;
		if (app.scrollBars) app.scrollBars.sleep();


		const $body = document.getElementsByTagName("body")[0];
		console.log($body);
		$body.classList.remove('main', 'donate');
		$body.classList.add(contName);
		if (app.scrollBars) app.scrollBars.update();
	},


	switchErrorMode: () => {

		const $show = document.querySelectorAll('.js-error');
		const $hide = document.querySelectorAll('.js-showBeforeDonate, .js-showAfterDonate');




		for (var i = 0; i < $show.length; i++) {

			$show[i].classList.remove('is-dNone');

		}

		for (var i = 0; i < $hide.length; i++) {

			$hide[i].classList.add('is-dNone');

		}

	},

	switchCheckingMode: () => {

		const $show = document.querySelectorAll('#js-checking');
		const $hide = document.querySelectorAll('.js-showBeforeDonate');

		for (var i = 0; i < $show.length; i++) {

			$show[i].classList.remove('is-dNone');

		}

		for (var i = 0; i < $hide.length; i++) {

			$hide[i].classList.add('is-dNone');

		}
	},

	//:::::::::::::::::::::::::::::::::::::::
	// Switch TO downlaod mode
	//:::::::::::::::::::::::::::::::::::::::
	switchDownloadMode: () => {

		// elemnts to show/hide
		const $show = document.querySelectorAll('.js-showAfterDonate');
		const $hide = document.querySelectorAll('.js-showBeforeDonate, #js-checking');

		for (var i = 0; i < $show.length; i++) {

			$show[i].classList.remove('is-dNone');

		}

		for (var i = 0; i < $hide.length; i++) {

			$hide[i].classList.add('is-dNone');

		}

		// Add download buttons to packs
		const $packs = document.querySelectorAll('.js-pack');
		for (var i = 0; i < $packs.length; i++) {
			var $pack = $packs[i];

			// find the zip file name
			let zip = $pack.getAttribute('data-zip');
			zip = zip + '.zip ';

			// Crete the button HTML
			const buttonHtml = `
				<li>
					<a class = "btn btn-box btn--brown" href = "https://getazoom.s3-us-west-2.amazonaws.com/${zip}" download>
						DOWNLOAD
					</a>
				</li>
			`;

			// ADD the button to HTML
			const $btnList = $pack.querySelector('.pack__ctas');
			$btnList.innerHTML = $btnList.innerHTML + buttonHtml;
		};
	},

	/////////////////////////////////////////
	// Sliders
	/////////////////////////////////////////
	initSlider: (packName) => {

		document.getElementById('gallery-wrapper').innerHTML = '';
		document.getElementById('thumb-wrapper').innerHTML = '';


		document.getElementById('overlay_title').innerHTML = app.data[packName].title;
		document.getElementById('overlay_subtitle').innerHTML = app.data[packName].subtitle;
		document.getElementById('packDescription').innerHTML = app.data[packName].packDescription;
		document.getElementById('packDescriptionList').innerHTML = '';

		for (var i = 0; i < app.data[packName].packDescriptionList.length; i++) {
			var li = document.createElement('li');
			li.innerHTML = app.data[packName].packDescriptionList[i];
			document.getElementById('packDescriptionList').appendChild(li);
		}



		for (var i = 0; i < app.data[packName].gallery.length; i++) {

			document.getElementById('gallery-wrapper').appendChild(app.getSlide(app.data[packName].gallery[i]));
			document.getElementById('thumb-wrapper').appendChild(app.getSlide(app.data[packName].thumbs[i]));

		}
		if (app.galleryThumbs) {
			app.galleryThumbs.destroy(true, true)
			app.galleryTop.destroy(true, true)
		}

		app.galleryThumbs = new Swiper('#packthumb-0', {
			direction: 'horizontal',
			loop: true,
			slidesPerView: 3,

			centeredSlides: true,
			navigation: {
				nextEl: '#packNext-0',
				prevEl: '#packPrev-0',
			}
		});
		app.galleryTop = new Swiper('#packgal-0', {
			direction: 'horizontal',
			loop: true,
			navigation: {
				nextEl: '#packNext-0',
				prevEl: '#packPrev-0',
			},
			thumbs: {
				swiper: app.galleryThumbs,
			},
		});


	},

	getSlide: (url) => {

		var slide = document.createElement('div');
		slide.classList.add("swiper-slide");
		var img = document.createElement('img');
		img.src = url;
		slide.appendChild(img);
		return slide;

	}

};

/////////////////////////////////////////
// START
/////////////////////////////////////////
app = window.app;
app.init();

//::::::::::::::::::::::::::::::::::::::::::::
//! WINDOW RESIZE EVENTS
//::::::::::::::::::::::::::::::::::::::::::::
window.onresize = app.onWindowResize;